import { create } from "@storybook/theming";

export default create({
  base: "light",
  brandTitle: "Alter Domus",
  brandUrl: "https://www.alterdomus.com",
  brandImage: "https://alterdomus.com/wp-content/uploads/2023/08/Logo.svg",
  brandTarget: "_self",
  appBg: "white",
  colorSecondary: "#D6532B",
  barSelectedColor: "#D6532B",
});
